import {FaPizzaSlice, FaHamburger} from 'react-icons/fa';
import {GiNoodles, GiChopsticks, GiHamburger, GiSadCrab, GiOctopus, GiShrimp, GiFruitBowl, GiBerriesBowl, GiJellyfish} from 'react-icons/gi';
import {FaFish} from 'react-icons/fa';
import {NavLink} from 'react-router-dom'
import React from 'react'
import '../componentsCSS/category.css'


const Category = () => {
  return (
    <div className='category'>
        <NavLink to={'/cuisine/Italian'} className="categoryItem">
            <FaPizzaSlice className='icons'/>
            <h4>Italian</h4>
        </NavLink>
        <NavLink to={'/cuisine/American'} className="categoryItem">
            <FaHamburger className='icons'/>
            <h4>American</h4>
        </NavLink>
        <NavLink to={'/cuisine/Thai'} className="categoryItem">
            <GiNoodles className='icons'/>
            <h4>Thai</h4>
        </NavLink>
        <NavLink to={'/cuisine/Indian'} className="categoryItem">
            <FaFish className='icons'/>
            <h4>Indian</h4>
        </NavLink>
        <NavLink to={'/cuisine/Chinese'} className="categoryItem">
            <GiChopsticks className='icons'/>
            <h4>Chinese</h4>
        </NavLink>
        <NavLink to={'/cuisine/French'} className="categoryItem">
            <GiBerriesBowl className='icons'/>
            <h4>French</h4>
        </NavLink>
        <NavLink to={'/cuisine/Spanish'} className="categoryItem">
            <GiShrimp className='icons'/>
            <h4>Spanish</h4>
        </NavLink>
        <NavLink to={'/cuisine/Mediterranean'} className="categoryItem">
            <GiSadCrab className='icons'/>
            <h4>Mediterranean</h4>
        </NavLink>
        <NavLink to={'/cuisine/Cajun'} className="categoryItem">
            <GiJellyfish className='icons'/>
            <h4>Cajun</h4>
        </NavLink>
        <NavLink to={'/cuisine/Caribbean'} className="categoryItem">
            <GiHamburger className='icons'/>
            <h4>Caribbean</h4>
        </NavLink>
        <NavLink to={'/cuisine/German'} className="categoryItem">
            <GiFruitBowl className='icons'/>
            <h4>German</h4>
        </NavLink>
        <NavLink to={'/cuisine/Hungarian'} className="categoryItem">
            <GiOctopus className='icons'/>
            <h4>Hungarian</h4>
        </NavLink>
      
    </div>
  )
}

export default Category
