import React, { useEffect, useState } from 'react';
import '../componentsCSS/popular.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import {Link} from "react-router-dom"

// const YOUR_API_KEY

const Popular = () => {
  const [popular, setPopular] = useState([]);
  useEffect(() => {
    getPopular();
  }, []);

  const getPopular = async () => {

    const check = localStorage.getItem("popular");

    if(check) {
      setPopular(JSON.parse(check));
    } else {
      const api = await fetch(
        `https://api.spoonacular.com/recipes/random?apiKey=${process.env.REACT_APP_API_KEY}&number=12`
      );

      const data = await api.json();

      localStorage.setItem("popular", JSON.stringify(data.recipes));
      setPopular(data.recipes);
      console.log(data.recipes);
    }


    
    
  };
  return (
    <>
      <div className="popular">
        <div className="wrapper">
          <h3>Popular Picks</h3>
          <Splide options={{ perPage: 4, arrows: false, pagination: false, drag: 'free', gap: "3rem" }}>
            {popular.map((recipe) => {
              return (
                <SplideSlide key={recipe.id}>
                  <div className="card">
                    <Link to={'/recipe/'+recipe.id}>
                      <img src={recipe.image} alt={recipe.title} />
                      <p>{recipe.title}</p>
                    <div className="backdrop"> </div>
                    </Link>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </div>
    </>
  );
};

export default Popular;
