import React from 'react';
import Category from './components/Category';
import Pages from './pages/Pages';
import { Link, BrowserRouter } from 'react-router-dom';
import Search from './components/Search';
import { GiKnifeFork } from 'react-icons/gi';
import './App.css';
function App() {
  return (
    <>
      <BrowserRouter>
          <div className="nav">
        <Link to={'/'}>
            <GiKnifeFork />
            <p>Delicious</p>
        </Link>
          </div>
        <Search />
        <Category />
        <Pages />
      </BrowserRouter>
    </>
  );
}

export default App;
