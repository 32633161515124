import React, { useEffect, useState } from 'react';
import '../componentsCSS/popular.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Link } from 'react-router-dom';

const Veggie = () => {
  const [veggie, setVeggie] = useState([]);
  useEffect(() => {
    getVeggie();
  }, []);

  const getVeggie = async () => {

    const check = localStorage.getItem("veggie");

    if(check) {
      setVeggie(JSON.parse(check));
    } else {
      const api = await fetch(
        `https://api.spoonacular.com/recipes/random?apiKey=${process.env.REACT_APP_API_KEY}&number=12&tags=vegetarian`
      );

      const data = await api.json();

      localStorage.setItem("veggie", JSON.stringify(data.recipes));
      setVeggie(data.recipes);
    }


    
    
  };
  return (
    <>
      <div className="veggie">
        <div className="wrapper">
          <h3>Our Vegeterian Picks</h3>
          <Splide options={{ perPage: 3, arrows: false, pagination: false, drag: 'free', gap: "3rem" }}>
            {veggie.map((recipe) => {
              return (
                <SplideSlide key={recipe.id}>
                  <div  className="card">
                  <Link to={'/recipe/'+recipe.id}>
                      <img src={recipe.image} alt={recipe.title} />
                      <p>{recipe.title}</p>
                    <div className="backdrop"> </div>
                    </Link>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </div>
    </>
  );
};

export default Veggie;
